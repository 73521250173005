import React, { Suspense, lazy } from 'react';

// Lazy loading the components
const Mainpage = lazy(() => import('./Mainpage/Mainpage'));
const Scrollingtext = lazy(() => import('./Scrollingtext'));
const Scrollering = lazy(() => import('./ImageSlider/Main_fourth'));
const Porjects_im = lazy(() => import('./Third_page/Porjects_im'));

function Appmain_() {
  return (
    <div className="overflow-hidden">
      <Suspense fallback={<div>Loading...</div>}>
        <Mainpage />
        <Scrollingtext />
        <Porjects_im />
        <Scrollering />
      </Suspense>
    </div>
  );
}

export default Appmain_;
