import React, { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { PopupContext } from "../Popup/Popupcontext";
import { AnimatePresence } from "framer-motion";
import Splashscreen from "../Splashscreen/Splashscreen";
import { motion } from "framer-motion"
import { Helmet } from "react-helmet-async";


const Sliderdetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const { sliderNumber, index } = useParams();
  const { currentLanguage } = useContext(PopupContext);
  const navigate = useNavigate();

  const sliderData = sliderNumber === "1" ? currentLanguage.slider1 : currentLanguage.slider2;
  const project = sliderData[index];
  const videoUrl = project.subscore;
  const imageurl = project.image;
  const url = project.url;


  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setIsVisible(true);
    }, 1000);
  }, [project]);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top of the page
  }, [project]);

  return (
    <AnimatePresence>
      {isLoading ? (
        <Splashscreen key="detail" words={[project.heading]} />
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: isVisible ? 1 : 0 }} // Animate based on isVisible
          transition={{ duration: 1 }} className="bg-[#E5E4E2]">
            <div className=" mx-auto  container ">
              <Helmet>
                <title>{project.title}</title>
                <meta name="description" content={project.description} />
                <meta name="keywords" content={project.keywords} />
              </Helmet>
        <motion.h1
                initial={{ y: 70 }}
                animate={{ y: 0 }}
                transition={{ duration: 2 }}className="text-3xl font-semibold mb-4  font-lato tracking-wide">{project.heading}</motion.h1>
            <motion.p
                initial={{ y: 70 }}
                animate={{ y: 0 }}
                transition={{ duration: 2 }} className="text-lg mb-6 font-lato mt-10 sm:mt-5 tracking-wide">{project.subheading}</motion.p>

          {videoUrl && (
              <motion.div
              initial={{ y: 70 }}
              animate={{ y: 0 }}
              transition={{ duration: 2 }} className="videoContainer mb-6   flex justify-center">
              <iframe
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${videoUrl}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                className="md:w-[60%] sm:w-[60%] w-[90%] md:h-96 sm:h-36 h-48 rounded-lg"
              ></iframe>
            </motion.div>
          )}

          {imageurl && (
            <motion.div
            initial={{ y: 70 }}
            animate={{ y: 0 }}
            transition={{ duration: 2 }} className="mb-6 flex justify-center" >
              <img src={imageurl} alt="Project" className="w-1/2 rounded-lg" />
            </motion.div>
              )}
              
              {
                url && (
                  <motion.div  initial={{ y: 70 }}
                  animate={{ y: 0 }}
                  transition={{ duration: 2 }} className="mb-7 font-lato tracking-wide mt-10  sm:mt-5">
                    <p>For move videos you can view this play list {<a href={url} className=" text-custom-lapliz">{url}</a>}</p>
                  </motion.div>
                )
              }
          
          <motion.div  
    initial={{ y: 70 }}
    animate={{ y: 0 }}
    transition={{ duration: 2 }} 
>
    <p className="font-lato leading-9 tracking-wide ">
        {project.description}
    </p>
</motion.div>


              <div className=" flex justify-center ">
              <button
            onClick={() => navigate('/')}
            className="mt-6 w-1/2  mb-10 py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            Exit
                  </button>
          </div>
            </div>
            </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Sliderdetails;
