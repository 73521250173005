import React, { useContext, useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./styles.css";
import Splashscreen from "../Splashscreen/Splashscreen";
import ImageContainer from "./ImageContainer";
import TextScroll from "./text";
import Circle from "../Assert/Circle";
import Magnetic from "../Framermotion/Magentic";
import Award from "./Award";
import { PopupContext } from "../Popup/Popupcontext";
import TextAbout from "./Text_About";
import { Helmet } from "react-helmet-async";
function About() {
  const [isLoading, setIsLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const { currentLanguage } = useContext(PopupContext);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
      setIsVisible(true);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <div>
    <AnimatePresence>
      {isLoading ? (
        <Splashscreen key="splashscreen" words={[currentLanguage.about]} />
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: isVisible ? 1 : 0 }}
          transition={{ duration: 1 }}
          className="flex flex-col relative justify-center"
        >
         <Helmet>
  {/* Page Title */}
  <title>About Nandhi Eswar - AI, React & Mobile App Developer</title>

  {/* Meta Description */}
  <meta
    name="description"
    content="Discover Nandhi Eswar’s journey in AI, React, iOS, and Android development. Explore innovative projects and technical expertise in web and mobile applications."
  />

  {/* Meta Keywords */}
  <meta
    name="keywords"
    content="Nandhi Eswar, About, AI Developer, React Developer, iOS Developer, Android Developer, Web Development, Full Stack, Portfolio"
  />

  {/* Canonical Link */}
  <link rel="canonical" href="https://nandhieswar.site/about" />

  {/* Open Graph for Social Media */}
  <meta property="og:title" content="About Nandhi Eswar - AI, React & Mobile Developer" />
  <meta property="og:description" content="Learn more about Nandhi Eswar’s experience in AI, web, and mobile development." />
  <meta property="og:url" content="https://nandhieswar.site/about" />
  <meta property="og:type" content="website" />

  {/* Twitter Meta Tags */}
  <meta name="twitter:title" content="About Nandhi Eswar - AI, React & Mobile Developer" />
  <meta name="twitter:description" content="Explore Nandhi Eswar’s journey in AI, React, and mobile app development." />
</Helmet>
          <motion.div className="flex flex-col w-screen justify-center text-center items-center relative bg-[#C4C4C4]">
            <motion.div className="md:text-9xl p-10 text-6xl vsm:text-4xl">
              <motion.h1
                initial={{ y: 70 }}
                animate={{ y: 0 }}
                transition={{ duration: 2 }}
                className=""
              >
                {currentLanguage.passsionated_in_the_digital_world}
              </motion.h1>
            </motion.div>

            <motion.div
              initial={{ y: 70 }}
              animate={{ y: 0 }}
              transition={{ duration: 2 }}
              className="items-center justify-end w-1/2 md:h-1 h-[0.5px] bg-[#A5A5A5] flex my-12 container md:mt-28"
            >
              <Magnetic>
                <motion.div className="bg-[#334BD3] text-white rounded-full md:h-[100px] md:w-[100px] h-[70px] w-[70px] items-center justify-center flex md:p-24 md:mr-28 vsm:h-[50px] vsm:w-[50px]">
                  <motion.p className="md:text-lg text-xl">
                    <Circle />
                  </motion.p>
                </motion.div>
              </Magnetic>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: isVisible ? 1 : 0 }} // Animate based on isVisible
              transition={{ duration: 1 }}
              className="flex md:flex-row flex-col-reverse text-center  mt-20 items-center justify-center "
            >
                  <ImageContainer />

              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: isVisible ? 1 : 0 }} // Animate based on isVisible
                transition={{ duration: 1 }}
                className="grid-container items-center justify-center"
              >
               <motion.div
  initial={{ opacity: 0 }}
  animate={{ opacity: isVisible ? 1 : 0 }}
  transition={{ duration: 1 }}
  className="flex items-center justify-center "
>
</motion.div>
<TextAbout isVisible={isVisible} />

              </motion.div>
            </motion.div>
            <motion.div className="relative flex flex-col justify-center p-10 container">
              <motion.div className="text-left justify-left items-left flex text-3xl mt-20">
                <motion.h1 className="text-left">
                  {currentLanguage.studies}
                </motion.h1>
              </motion.div>
              <motion.div className="flex md:flex-row justify-center p-1 gap-2 flex-col">
                <TextScroll />
              </motion.div>
            </motion.div>
          </motion.div>
          <Award />
        </motion.div>
      )}
      </AnimatePresence>
    </div>
  );
}

export default About;
